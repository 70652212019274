const REDIRECT_URL_KEY = 'redirect-url'
const TARGET_KEY = 'redirect-target'
const TARGET_DEFAULT_KEY = 'mercafacil'
const REDIRECT_QUERY_PARAM_KEYS = ['redirect-url', 'redirect_url', 'return_to']

class IntegrationService {
  getRedirectURL() {
    return JSON.parse(window.localStorage.getItem(REDIRECT_URL_KEY) || 'null')
  }

  hasRedirect() {
    return !!this.getRedirectURL()
  }

  getTarget() {
    return window.localStorage.getItem(TARGET_KEY) || TARGET_DEFAULT_KEY
  }

  isZendeskIntegration() {
    return this.getTarget() === 'zendesk'
  }

  storeValues(redirectUrl, target = TARGET_DEFAULT_KEY) {
    window.localStorage.setItem(REDIRECT_URL_KEY, JSON.stringify(redirectUrl))
    window.localStorage.setItem(TARGET_KEY, target)
  }

  resetStorage() {
    const oldRedirectReferrer = this.getRedirectURL()
    const oldTargetReferrer = this.getTarget()

    window.localStorage.clear()

    if (oldRedirectReferrer) {
      this.storeValues(oldRedirectReferrer, oldTargetReferrer)
    }
  }

  getQueryParams() {
    const result = {
      redirectUrl: null,
      shouldDirectRedirect: false,
    }

    if (window.location.href.indexOf('?') !== -1) {
      const queryStrings = window.location.href.slice(window.location.href.indexOf('?') + 1).split('&')

      for (const queryString of queryStrings) {
        const [key, value] = queryString.split('=')
        if (REDIRECT_QUERY_PARAM_KEYS.includes(key)) {
          result.redirectUrl = value
        } else if (key === 'redirect' && value === 'true') {
          result.shouldDirectRedirect = true
        }
      }
    }

    return result
  }

  getDefaultRedirectURL() {
    try {
      const { hostname } = window.location
      if (hostname.startsWith('mf-login-beta') || hostname.startsWith('localhost')) {
        return process.env.VUE_APP_DEFAULT_BETA_REDIRECT_URL
      }
    } catch (error) {
      console.log('Error trying to get default redirect URL', error)
    }
    return process.env.VUE_APP_DEFAULT_PROD_REDIRECT_URL
  }

  redirectToIntegration(authCode) {
    let redirectURL = this.getRedirectURL()

    if (this.isZendeskIntegration()) {
      const zendeskURL = process.env.VUE_APP_ZENDESK_JWT_URL
      window.location.href = `${zendeskURL}?return_to=${redirectURL}&jwt=${authCode}`
      return
    }

    if (!redirectURL) {
      redirectURL = this.getDefaultRedirectURL()
    }

    window.location.href = `${redirectURL}?auth_code=${authCode}`
  }
}

export default new IntegrationService()

<template>
  <v-app id="app">
    <v-main>
      <v-container v-if="!loading" class="pa-0" fluid>
        <transition-group name="slide-up">
          <div key="page">
            <router-view class="child-view" />
          </div>
        </transition-group>
      </v-container>
      <mf-loading-dialog v-else loading />
    </v-main>
  </v-app>
</template>

<script>
import { mapActions, mapState } from 'pinia'

import { useStore as mainUseStore } from '@/store'
import { useStore as loginUseStore } from '@/modules/login/store'
import integrationService from '@/services/IntegrationService'

export default {
  data: () => ({
    loading: true,
    isRedirecting: false,
  }),
  computed: {
    ...mapState(mainUseStore, {
      source: 'getCurrentSource',
    }),
  },
  async mounted() {
    this.$setIsMultiUser(false)

    integrationService.resetStorage()

    this.getRedirectURLParams()

    await this.setCurrentSource()

    if (this.isLogout()) {
      await this.singleLogout(this.$apollo)
    } else {
      const validAuthCode = await this.checkIsCookieValid()

      if (validAuthCode) {
        const multiUser = await this.getMe(this.$apollo)
        const isZendesk = integrationService.isZendeskIntegration()

        if (multiUser && !isZendesk) {
          this.$setIsMultiUser(true)
          this.loading = false
          this.$router.push({ name: 'login' })
          return
        }

        integrationService.redirectToIntegration(validAuthCode)

        this.isRedirecting = true
        return
      }
    }

    if (!this.isRedirecting) this.loading = false
    if (this.$route.fullPath === '/') return
    if (this.$route.name === 'confirm-email') return
    this.$router.push({ name: 'login' })
  },
  methods: {
    ...mapActions(mainUseStore, ['setCurrentSource', 'setDirectRedirect']),
    ...mapActions(loginUseStore, ['getCode', 'singleLogout', 'getMe']),

    async checkIsCookieValid() {
      try {
        const payload = {
          source: this.source,
          target: integrationService.getTarget(),
        }

        const { authCode } = await this.getCode(this.$apollo, payload)

        return authCode
      } catch (error) {
        console.error('Error trying to get auth code', error)
        return null
      }
    },

    isLogout() {
      const queryParams = new URLSearchParams(window.location.search)
      if (queryParams && queryParams.has('kind') && queryParams.get('kind') === 'error') {
        console.error('Error trying to access Zendesk:', queryParams.get('message'))
        return true
      }

      return this.$router?.history?.pending?.name === 'logout'
    },

    getRedirectURLParams() {
      const { redirectUrl, shouldDirectRedirect } = integrationService.getQueryParams()

      if (shouldDirectRedirect) {
        this.setDirectRedirect(true)
      }

      if (redirectUrl && redirectUrl !== integrationService.getRedirectURL()) {
        integrationService.storeValues(redirectUrl)
      }
    },
  },
}
</script>

<style lang="scss">
@import '@/assets/styles/global.scss';

.v-application {
  font-family: 'Lato', sans-serif !important;

  .title,
  .subtitle {
    font-family: 'Lato', sans-serif !important;
  }
}
html {
  overflow: auto !important;
}
body {
  background-color: #f3f3f3 !important;
}
.primary-text {
  opacity: 0.87 !important;
}
.secondary-text {
  opacity: 0.54 !important;
}
</style>

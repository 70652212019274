import { enums } from '@mf-node/mf-vue-components'

export default {
  getCurrentSource: (state) => state.source,
  getDirectRedirect: (state) => state.directRedirect,
  getCurrentLogo: (state) => enums.assetsSources.getAssetsSource(state.source).logo,
  getCurrentBackground: (state) => enums.assetsSources.getAssetsSource(state.source).background,
  getCurrentTheme: (state) => enums.assetsSources.getAssetsSource(state.source).theme,
  getIsAdmin: (state) => state.source === enums.authSources.getAuthSource('ADMIN'),
  getHasLoginForm: (state) => [enums.authSources.getAuthSource('STARLORD'), enums.authSources.getAuthSource('STORYBOOK')].includes(state.source),
}

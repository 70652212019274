import { getAuthSource } from '@/helpers/authSource'
import { getAncestorOrigin } from '@/helpers/ancestorOrigins'

export default {
  async setCurrentSource() {
    const ancestorOrigin = getAncestorOrigin()
    this.source = getAuthSource(ancestorOrigin)
  },

  async setDirectRedirect(directRedirect) {
    this.directRedirect = directRedirect
  },
}
